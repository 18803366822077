import * as React from 'react';
import { Fragment } from 'react';
import styled from 'styled-components';
import { Button, LayoutItem, Text } from '@audi/audi-ui-react';
import { VariantConfig } from './PartnerBusinessCard';
import { GEO_FENCE } from './Icons';
import { useTrackingManager } from '../useTrackingManager';
import { JsonLd } from './Json-ld';
import { PartnerContext } from '../PartnerProvider';

const StyledHeadlineAsLink = styled.div`
  > a {
    border-bottom: 0;
  }
`;

const AddressContainer = styled(LayoutItem)`
  max-width: 375px;
`;

export const PartnerBusinessCardTag = (variantConfig: VariantConfig): React.ReactElement => {
  const { partner } = React.useContext(PartnerContext);
  const trackingManager = useTrackingManager();
  const { hasOuterSpacing, isGoogleMapLink, isLinkedToPWS, showJsonLd } = variantConfig;
  const partnerName = partner.nameEnhanced;

  const partnerAddressSize = partner.address.length;

  const partnerAddress =
    partnerAddressSize &&
    partner.address.map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Fragment key={index}>{`${item}${index === partnerAddressSize - 1 ? '' : ', '}`}</Fragment>
    ));

  return (
    <AddressContainer spacing={hasOuterSpacing ? ['m'] : undefined}>
      {partner && (
        <>
          {showJsonLd && <JsonLd data={partner} />}
          <Text as="h2" variant="order4" weight="bold" data-testid="name">
            {isLinkedToPWS && partner.url ? (
              <StyledHeadlineAsLink>
                <a href={partner.url}>{partnerName}</a>
              </StyledHeadlineAsLink>
            ) : (
              partnerName
            )}
          </Text>
          {isGoogleMapLink && (
            <Button
              variant="text"
              icon={GEO_FENCE}
              href={partner.directionsUrl}
              onClick={() =>
                trackingManager.click(
                  'exit_link',
                  partner.directionsUrl,
                  'link',
                  `${partner.address[0]} ${partner.address[1]}`,
                  partnerName,
                  '1',
                  'location'
                )
              }
              data-testid="directions"
              spaceStackStart="s"
            >
              {partnerAddress}
            </Button>
          )}
        </>
      )}
    </AddressContainer>
  );
};
