import * as React from 'react';
import { LayoutItem } from '@audi/audi-ui-react';
import { OpeningHours } from './OpeningHours';
import { AddressAndContact } from './AddressAndContact';
import { VariantConfig } from './PartnerBusinessCard';
import { PartnerBusinessCardHeader } from './PartnerBusinessCardHeader';
import { JsonLd } from './Json-ld';
import { PartnerContext } from '../PartnerProvider';

export const PartnerBusinessCardFixedNarrow = (
  variantConfig: VariantConfig
): React.ReactElement => {
  const { partner, hasOpeningHours } = React.useContext(PartnerContext);

  const {
    ctaBarButtonConfig = [],
    displayCtaBar = false,
    displayEmail = false,
    displayOpeningHours = false,
    displayPhone = false,
    isGoogleMapLink = false,
    isLinkedToPWS,
    showMoreInfoLink,
    showImage,
    showServiceList,
    showJsonLd,
  } = variantConfig;
  return (
    <>
      {partner && (
        <>
          {showJsonLd && <JsonLd data={partner} />}
          <PartnerBusinessCardHeader
            partner={partner}
            isNarrow
            showImage={showImage}
            showServiceList={showServiceList}
            isLinkedToPWS={isLinkedToPWS}
          />
          <LayoutItem spaceStackStart="xxxl">
            <AddressAndContact
              isNarrow
              isLinkedToPWS={isLinkedToPWS}
              displayEmail={displayEmail}
              displayPhone={displayPhone}
              isGoogleMapLink={isGoogleMapLink}
              showMoreInfoLink={showMoreInfoLink}
              displayCtaBar={displayCtaBar}
              ctaBarButtonConfig={ctaBarButtonConfig}
            />
          </LayoutItem>

          {displayOpeningHours && hasOpeningHours && (
            <LayoutItem spaceStackStart="xl">
              <OpeningHours data={partner} isNarrow />
            </LayoutItem>
          )}
        </>
      )}
    </>
  );
};
