import React from 'react';
import { Button } from '@audi/audi-ui-react';
import { useTrackingManager } from '../useTrackingManager';
import { CtaButtonProps } from './CtaBar';
import { getCtaLabel } from '../../lib/ctaLabel';

export type CtaUrlButtonProps = CtaButtonProps & {
  url: string;
};

export const CtaUrlButton = ({
  variant,
  url,
  label,
  displayname,
  position,
}: CtaUrlButtonProps): React.ReactElement => {
  const trackingManager = useTrackingManager();
  return (
    <Button
      variant={variant}
      href={url}
      onClick={() => {
        trackingManager.click('internal_link', url, 'cta', label, displayname, position, label);
      }}
    >
      {getCtaLabel(label)}
    </Button>
  );
};
