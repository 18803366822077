export const constants = {
  displaySizes: {
    mobile: '375px',
    tablet: '768px',
    smallDesktop: '1024px',
    desktop: '1440px',
    largeDesktop: '1920px',
  },
};

export const sizes = {
  desktop: {
    'Show all dealer info (narrow layout)': '1400px',
    'Show all dealer info (long layout)': '1200px',
    'Show dealer name, address, and contact information only': '160px',
    'Show dealer name and address only': '94px',
  },
  tablet: {
    'Show all dealer info (narrow layout)': '1300px',
    'Show all dealer info (long layout)': '1300px',
    'Show dealer name, address, and contact information only': '160px',
    'Show dealer name and address only': '64px',
  },
  mobile: {
    'Show all dealer info (narrow layout)': '1200px',
    'Show all dealer info (long layout)': '800px',
    'Show dealer name, address, and contact information only': '160px',
    'Show dealer name and address only': '110px',
  },
};
