import * as React from 'react';
import { Layout, LayoutItem, useStyledTheme, Text, Button } from '@audi/audi-ui-react';
import { useI18n } from '@volkswagen-onehub/audi-i18n-context';
import styled from 'styled-components';
import { transformServices } from '../../lib/transformServices';
import { messages } from '../../i18n/messages';
import { useTrackingManager } from '../useTrackingManager';
import { useMedia } from '../../lib/hooks';
import { Image } from './Image';
import { BOOKMARK_ICON } from './Icons';

const StyledImage = styled.figure`
  position: relative;
  margin: 0;
  /* create aspect ratio box with an aspect ratio of 16:9 */
  padding: 0 0 56.25% 0;
  overflow: hidden;

  > img {
    display: block;
    position: absolute;
    width: 100%;
    height: auto;
  }
`;

const StyledHeadlineAsLink = styled.div`
  > a {
    border-bottom: 0;
    font-weight: normal;
  }
`;

interface PartnerBusinessCardHeaderProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  partner: any;
  // eslint-disable-next-line react/require-default-props
  isNarrow?: boolean;
  // eslint-disable-next-line react/require-default-props
  showImage?: boolean;
  // eslint-disable-next-line react/require-default-props
  showServiceList?: boolean;
  // eslint-disable-next-line react/require-default-props
  isLinkedToPWS?: boolean;
}

export const PartnerBusinessCardHeader = (
  props: PartnerBusinessCardHeaderProps
): React.ReactElement => {
  const {
    partner,
    isNarrow,
    showImage = true,
    showServiceList = true,
    isLinkedToPWS = true,
  } = props;
  const trackingManager = useTrackingManager();

  const myAudiLinkLabel = useI18n(messages.myAudiLinkLabel);

  const partnerServiceLabels = new Map([
    ['sales', useI18n(messages.partnerServiceSales)],
    ['sales-r8', useI18n(messages.partnerServiceSalesR8)],
    ['service', useI18n(messages.partnerServiceService)],
    ['etron', useI18n(messages.partnerServiceEtron)],
    ['usedcars', useI18n(messages.partnerServiceUsedcars)],
  ]);

  const services: any = transformServices(
    partner.services,
    partner.additionalServices,
    partnerServiceLabels
  );
  // // TODO Check imgWidth value
  const imgWidth = 328;

  const viewportTheme = useStyledTheme();

  const isViewportM = useMedia(`only screen and (min-width: ${viewportTheme.breakpoints.m}px) `);
  const isViewportL = useMedia(`only screen and (min-width: ${viewportTheme.breakpoints.l}px) `);

  const headlineOrder = isNarrow ? 'order3' : 'order1';
  const showColumnItemPicture = (showImage && isNarrow) || (!isViewportM && !isNarrow);
  const partnerName = partner.nameEnhanced;

  const partnerImage = (
    <StyledImage>
      <Image
        loadingAnimation="fade"
        src={`${partner.locationImageUrl}?imdensity=1&imwidth=${imgWidth}`}
        data-testid="image"
        alt={partner.officialName}
        sizes={[
          {
            condition: `(max-width: ${imgWidth}px)`,
            value: `${imgWidth}px`,
          },
          {
            condition: '(max-width: 639px)',
            value: '656px',
          },
          {
            condition: '(max-width: 1023px)',
            value: `${imgWidth}px`,
          },
          {
            value: '656px',
          },
        ]}
        srcSet={[
          {
            src: `${partner.locationImageUrl}?imdensity=1&imwidth=${imgWidth}`,
            desc: `${imgWidth}w`,
          },
          {
            src: `${partner.locationImageUrl}?imdensity=1&imwidth=656`,
            desc: '656w',
          },
        ]}
      />
    </StyledImage>
  );

  return (
    <>
      <Layout
        direction={{ xs: 'column', l: 'row' }}
        wrap="nowrap"
        align="stretch"
        justify="between"
      >
        <LayoutItem align="stretch">
          {partner.locationImageUrl && showColumnItemPicture && (
            <LayoutItem spaceStackEnd="l">
              {isLinkedToPWS ? (
                <a
                  onClick={() =>
                    trackingManager.click(
                      'exit_link',
                      partner.url,
                      'image',
                      '',
                      partnerName,
                      '1',
                      'pws link'
                    )
                  }
                  href={partner.url}
                  target={isNarrow ? '_blank' : ''}
                  rel="noreferrer"
                >
                  {partnerImage}
                </a>
              ) : (
                <>{partnerImage}</>
              )}
            </LayoutItem>
          )}
          <Text as="h2" variant={headlineOrder} weight="normal" data-testid="name">
            {isLinkedToPWS ? (
              <StyledHeadlineAsLink>
                <a
                  onClick={() =>
                    trackingManager.click(
                      'exit_link',
                      partner.url,
                      'headline',
                      partnerName,
                      partnerName,
                      '1',
                      'pws link'
                    )
                  }
                  href={partner.url}
                  target={isNarrow ? '_blank' : ''}
                  rel="noreferrer"
                >
                  {partnerName}
                </a>
              </StyledHeadlineAsLink>
            ) : (
              ''
            )}
          </Text>
          {showServiceList ? (
            <Text weight="normal" data-testid="services" spaceStackStart="s" variant="order4">
              {services}
            </Text>
          ) : null}
        </LayoutItem>

        {false && (
          <LayoutItem
            spaceInlineStart={isViewportL ? 'xxl' : undefined}
            spaceStackStart={isViewportL ? 's' : 'xxl'}
            spaceStackEnd={!isViewportL ? 'm' : undefined}
          >
            <Button
              variant="text"
              href={partner.myAudiUrl}
              icon={BOOKMARK_ICON}
              onClick={() =>
                trackingManager.click(
                  'internal_link',
                  partner.myAudiUrl,
                  'link',
                  myAudiLinkLabel,
                  partnerName,
                  '',
                  'myaudi link'
                )
              }
              data-testid="myaudi"
            >
              {useI18n(messages.myAudiLinkLabel)}
            </Button>
          </LayoutItem>
        )}
      </Layout>
    </>
  );
};
