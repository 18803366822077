/* eslint-disable */
import * as React from 'react';

export const BOOKMARK_ICON = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="M18.5 22.5L12 16 5.5 22.5 5.5 0.5 18.5 0.5z"
      stroke="currentColor"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export const ADDRESS_BOOK = (
  <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.5,0.5 L18.5,0.5 L18.5,23.5 L1.5,23.5 L1.5,0.5 Z M18.5,15.5 L20.5,15.5 C21.1,15.5 21.5,15.9 21.5,16.5 L21.5,22.5 C21.5,23.1 21.1,23.5 20.5,23.5 L18.5,23.5 M18,7.5 L20.5,7.5 C21.1,7.5 21.5,7.9 21.5,8.5 L21.5,14.5 C21.5,15.1 21.1,15.5 20.5,15.5 L18,15.5 M18.5,0.5 L20.5,0.5 C21.1,0.5 21.5,0.9 21.5,1.5 L21.5,6.5 C21.5,7.1 21.1,7.5 20.5,7.5 L18.5,7.5 M13.5,12.5 C14,12.5 14.5,12.9 14.5,13.5 L14.5,17.5 L5.5,17.5 L5.5,17.5 L5.5,13.5 C5.5,13 6,12.5 6.5,12.5 L13.5,12.5 Z M10.5,5.5 C11.6,5.5 12.5,6.4 12.5,7.5 L12.5,8.5 C12.5,9.6 11.6,10.5 10.5,10.5 L9.5,10.5 C8.4,10.5 7.5,9.6 7.5,8.5 L7.5,7.5 C7.5,6.4 8.4,5.5 9.5,5.5 L10.5,5.5 Z"
      stroke="currentColor"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export const VOICE_CALL = (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="👤Icons/Content/voice-call-1-small/black"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="voice-call-1-small" transform="translate(1.000000, 2.000000)" stroke="#000000">
        <path d="M17.5,20.3 L21.5,16.5 C21.7,16.3 21.7,15.7 21.5,15.5 L18.5,12.5 C18.3,12.3 17.7,12.3 17.5,12.5 L16,13.8 C16,13.8 15.3,14.9 11.1,10.7 L10.6,10.2 C6.3,5.9 7.5,5.5 7.5,5.5 L8.5,4.5 C8.7,4.3 8.7,3.7 8.5,3.5 L5.5,0.5 C5.3,0.3 4.7,0.3 4.5,0.5 L0.7,4.5 C0.7,4.5 6.5,14.1 17.5,20.3 Z"></path>
      </g>
    </g>
  </svg>
);

export const SAVE_AS = (
  <svg
    width="24px"
    height="25px"
    viewBox="0 0 24 25"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="👤Icons/System/system-save-as-small/black"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="system-save-as-small" transform="translate(1.000000, 2.000000)" stroke="#000000">
        <path d="M5.5,9 L5.5,15.5 L17.5,15.5 L17.5,9 M11.5,7 L11.5,13.4"></path>
        <polyline points="8.262 10.154 11.51 13.4 14.739 10.171"></polyline>
        <circle id="Oval" cx="11.5" cy="11.5" r="11"></circle>
      </g>
    </g>
  </svg>
);
export const DIRECT_MAIL = (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="👤Icons/Content/direct-mail-small/black"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="direct-mail-small" transform="translate(1.000000, 4.000000)" stroke="#000000">
        <path d="M21.5,15.5 L2.5,15.5 C1.3954305,15.5 0.5,14.6045695 0.5,13.5 L0.5,0.5 L19.5,0.5 C20.6045695,0.5 21.5,1.3954305 21.5,2.5 L21.5,15.5 Z"></path>
        <polyline points="4 5.5 11 11 18 5.5"></polyline>
      </g>
    </g>
  </svg>
);

export const GEO_FENCE = (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="👤Icons/Content/geofence-small/black"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="geofence-small" transform="translate(2.000000, 1.000000)" stroke="#000000">
        <path d="M14.43,16.5 C17.88,16.92 20.43,18.04 20.43,19.03 C20.43,20.39 15.86,21.5 10.31,21.5 C4.76,21.5 0.31,20.39 0.31,19.03 C0.31,18.03 2.84,16.88 6.46,16.5"></path>
        <path d="M10.5,18 C10.5,18 4.68,9.73 4.68,6.47 C4.546,3.209 7.239,0.384 10.5,0.25 C13.761,0.384 16.454,3.209 16.32,6.47 C16.32,9.73 10.5,18 10.5,18 Z"></path>
        <path d="M10.5,4 C11.897,4 13,5.103 13,6.5 C13,7.897 11.897,9 10.5,9 C9.103,9 8,7.897 8,6.5 C8,5.103 9.103,4 10.5,4 Z"></path>
      </g>
    </g>
  </svg>
);

export const WWW_LINK = (
  <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path d="M19.8,20.5 L16.7,12.8 M14.9,17.2 L16.7,12.8 L21.1,14.6" stroke="currentColor" />
      <path
        d="M16.1,7 L17.3,7 L18.4,9.7 L19.4,7 L20.7,7 L21.7,9.7 L22.8,7 L24,7 L22.3,10.8 L21,10.8 L20,8.2 L19,10.8 L17.7,10.8 L16.1,7 Z M8,7 L9.2,7 L10.3,9.7 L11.3,7 L12.6,7 L13.6,9.7 L14.8,7 L16,7 L14.3,10.8 L13,10.8 L12,8.2 L11,10.8 L9.7,10.8 L8,7 Z M0,7 L1.2,7 L2.3,9.7 L3.3,7 L4.6,7 L5.6,9.7 L6.7,7 L7.9,7 L6.2,10.8 L5,10.8 L4,8.2 L3,10.8 L1.7,10.8 L0,7 Z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </svg>
);
