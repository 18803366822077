import React from 'react';
import { useI18n } from '@volkswagen-onehub/audi-i18n-context';
import { messages } from '../i18n/messages';

export function getCtaLabel(label: string): React.ReactElement {
  switch (label) {
    case 'request testdrive':
      return <>{useI18n(messages.ctaLabelRequestTestdrive)}</>;
    case 'schedule service':
      return <>{useI18n(messages.ctaLabelScheduleService)}</>;
    case 'contact us':
      return <>{useI18n(messages.ctaLabelContactUs)}</>;
    default:
      return <>{label}</>;
  }
}
