export const messages = {
  fax: {
    defaultMessage: 'Fax',
    id: 'partnerBusinessCard.fax',
  },
  phone: {
    defaultMessage: 'Phone',
    id: 'partnerBusinessCard.phone',
  },
  directionsLinkLabel: {
    defaultMessage: 'Directions',
    id: 'partnerBusinessCard.directionsLinkLabel',
  },
  vCardLinkLabel: {
    defaultMessage: 'Download contact data',
    id: 'partnerBusinessCard.vCardLinkLabel',
  },
  linkLabel: { defaultMessage: 'More Info', id: 'partnerBusinessCard.linkLabel' },
  myAudiLinkLabel: {
    defaudefaultMessagelt: 'Save in myAudi',
    id: 'partnerBusinessCard.myAudiLinkLabel',
  },
  addressAndContactLabel: {
    defaultMessage: 'Address',
    id: 'partnerBusinessCard.addressAndContact.label',
  },
  partnerServiceSales: { defaultMessage: 'Car sales', id: 'service.sales' },
  partnerServiceSalesR8: { defaultMessage: 'R8 partner', id: 'service.sales-r8' },
  partnerServiceService: { defaultMessage: 'Service partner', id: 'service.service' },
  partnerServiceEtron: { defaultMessage: 'e-tron', id: 'service.e-tron' },
  partnerServiceUsedcars: {
    defaultMessage: 'Audi Approved :plus',
    id: 'service.audi-approved-plus',
  },
  openingHoursHeadline: { defaultMessage: 'Opening hours', id: 'openingHours.headline' },
  openingHoursLabelOpenUntil: { defaultMessage: 'Open until', id: 'openingHours.labelOpenUntil' },
  openingHoursLabelClosesSoon: {
    defaultMessage: 'Closes soon',
    id: 'openingHours.labelClosesSoon',
  },
  openingHoursLabelClosed: { defaultMessage: 'Closes soon', id: 'openingHours.labelClosed' },
  openingHoursLabelWillOpenAt: { defaultMessage: 'Opens', id: 'openingHours.labelWillOpenAt' },
  openingHoursLabelStatusIsUnknown: {
    defaultMessage: 'No opening hours available',
    id: 'openingHours.labelStatusIsUnknown',
  },
  openingHoursDayClosed: { defaultMessage: 'Closed', id: 'openingHours.dayClosed' },
  openingHoursLabel: { defaultMessage: 'Opening hours', id: 'openingHours.label' },
  ctaLabelRequestTestdrive: {
    defaultMessage: 'Book a test drive',
    id: 'cta.labelRequestTestdrive',
  },
  ctaLabelScheduleService: {
    defaultMessage: 'Request a service appointment',
    id: 'cta.labelScheduleService',
  },
  ctaLabelPbcAnchor: { defaultMessage: 'Overall contact', id: 'cta.labelPbcAnchor' },
  ctaLabelContactUs: { defaultMessage: 'Contact us', id: 'cta.labelContactUs' },
  missingPartnerDataErrorHeader: {
    defaultMessage: 'Error',
    id: 'partnerBusinessCard.missingPartnerDataErrorHeader',
  },
  missingPartnerDataErrorMessage: {
    defaultMessage: 'Partner data does not exist.',
    id: 'partnerBusinessCard.missingPartnerDataErrorMessage',
  },
};
