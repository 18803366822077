import * as React from 'react';
import { LayoutItem } from '@audi/audi-ui-react';

import { OpeningHours } from './OpeningHours';
import { AddressAndContact } from './AddressAndContact';
import { VariantConfig } from './PartnerBusinessCard';
import { PartnerBusinessCardHeader } from './PartnerBusinessCardHeader';
import { JsonLd } from './Json-ld';
import { PartnerContext } from '../PartnerProvider';

export const PartnerBusinessCardFullLong = (variantConfig: VariantConfig): React.ReactElement => {
  const { partner, hasOpeningHours } = React.useContext(PartnerContext);

  const {
    ctaBarButtonConfig = [],
    displayCtaBar = false,
    displayEmail = false,
    displayOpeningHours = false,
    displayPhone = false,
    isGoogleMapLink = false,
    isLinkedToPWS,
    showMoreInfoLink,
    showImage,
    showServiceList,
    showJsonLd,
  } = variantConfig;

  return (
    <>
      {partner && (
        <>
          {showJsonLd && <JsonLd data={partner} />}
          <PartnerBusinessCardHeader
            partner={partner}
            isLinkedToPWS={isLinkedToPWS}
            showImage={showImage}
            showServiceList={showServiceList}
          />
          <LayoutItem spaceStackStart="xxl">
            <AddressAndContact
              displayCtaBar={displayCtaBar}
              ctaBarButtonConfig={ctaBarButtonConfig}
              displayEmail={displayEmail}
              displayPhone={displayPhone}
              isGoogleMapLink={isGoogleMapLink}
              isLinkedToPWS={isLinkedToPWS}
              showMoreInfoLink={showMoreInfoLink}
            />
          </LayoutItem>

          {displayOpeningHours && hasOpeningHours && (
            <LayoutItem spaceStackStart="xxxl">
              <OpeningHours data={partner} isNarrow />
            </LayoutItem>
          )}
        </>
      )}
    </>
  );
};
