import styled from 'styled-components';
import { constants, sizes } from '../constants';

const device = {
  desktop: `(min-width:${constants.displaySizes.desktop})`,
  largeDesktop: `(min-width:${constants.displaySizes.largeDesktop})`,
  smallDesktop: `(min-width:${constants.displaySizes.smallDesktop})`,
  mobile: `(max-width:${constants.displaySizes.mobile})`,
  tablet: `(min-width: ${constants.displaySizes.tablet})`,
  smallTablet: `(max-width: ${constants.displaySizes.tablet})`,
};

interface ContainerProps {
  variant: any;
  standAlonePBC?: boolean;
}

export const Container = styled.div<ContainerProps>`
  grid-template-columns: auto;
  min-height: ${(props) => (sizes.mobile as any)[props.variant]};
  padding: ${(props) => (props.standAlonePBC ? '0px 16px' : '0px')};

  @media ${device.tablet} {
    column-gap: 17px;
    grid-template-columns: auto auto;
    padding: ${(props) => (props.standAlonePBC ? '0px 28px' : '0px')};
  }
  @media ${device.tablet} {
    column-gap: 17px;
    grid-template-columns: auto auto;
    min-height: ${(props) => (sizes.tablet as any)[props.variant]};
    padding: ${(props) => (props.standAlonePBC ? '0px 40px' : '0px')};
  }
  @media ${device.smallDesktop} {
    column-gap: 48px;
    min-height: ${(props) => (sizes.desktop as any)[props.variant]};
    padding: ${(props) => (props.standAlonePBC ? '0px 60px' : '0px')};
  }
  @media ${device.desktop} {
    column-gap: 97px;
    padding: ${(props) => (props.standAlonePBC ? '0px 96px' : '0px')};
  }
  @media ${device.largeDesktop} {
    column-gap: 60px;
    padding: ${(props) => (props.standAlonePBC ? '0px 96px' : '0px')};
  }
`;
