/**
 * Context Provider for Feature App
 * @see: https://github.com/volkswagen-onehub/audi-feature-app-hello-world/blob/master/src/provider.tsx
 */
import { AudiPlatformProvider } from '@audi/audi-ui-react';
import { InViewContextProvider } from '@volkswagen-onehub/audi-etron-gt-utils-feature-app';
import * as React from 'react';
import {
  ContentService,
  Logger,
  QueryCacheService,
  TrackingService,
  GfaLocaleServiceV1,
} from './feature-service-types';
import FeatureServiceContext from './FeatureServiceContext';
import { FeatureAppAsyncState, FeatureAppAsyncStateHolder } from './FeatureHubAppDefinition';

export interface Env {
  dealerSearchServiceEndpointUrl: string;
}
export interface ProviderProps {
  children?: React.ReactNode;
  env?: Env;
  localeService: GfaLocaleServiceV1;
  logger: Logger;
  trackingService?: TrackingService;
  asyncStateHolder?: FeatureAppAsyncStateHolder;
  contentService?: ContentService;
  queryCacheService: QueryCacheService;
}

export const FeatureAppProvider = (props: ProviderProps): React.ReactElement => {
  let { env } = props;
  const {
    children,
    localeService,
    logger,
    trackingService,
    asyncStateHolder,
    contentService,
    queryCacheService,
  } = props;

  const [asyncState, setAsyncState] = React.useState<FeatureAppAsyncState | undefined>(
    typeof asyncStateHolder === 'object' ? asyncStateHolder : undefined
  );

  React.useEffect(() => {
    let mounted = true;
    if (typeof asyncStateHolder === 'function') {
      asyncStateHolder().then((state) => {
        if (mounted) {
          setAsyncState(state);
        }
      });
    }

    return () => {
      mounted = false;
    };
  }, [asyncStateHolder]);

  if (!env && !!asyncState) {
    env = asyncState.env;
  }

  return (
    <>
      {!!env && (
        <InViewContextProvider logger={logger}>
          <FeatureServiceContext.Provider
            value={{
              env,
              localeService,
              logger,
              trackingService,
              contentService,
              queryCacheService,
            }}
          >
            <AudiPlatformProvider>{children}</AudiPlatformProvider>
          </FeatureServiceContext.Provider>
        </InViewContextProvider>
      )}
    </>
  );
};
