export interface FalconContent {
  __type: 'aem-headless';
  elements: {
    partnerID: string;
    showVariant: string;
    variantConfig: {
      displayOpeningHours: boolean;
      displayCtaBar: boolean;
      displayOfficialName: boolean;
      displayPhone: boolean;
      displayEmail: boolean;
      isLinkedToPWS: boolean;
      showMoreInfoLink: boolean;
      hasOuterSpacing: boolean;
      isGoogleMapLink: boolean;
      showImage: boolean;
      showServiceList: boolean;
      showJsonLd: boolean;
      ctaBarButtonConfig: [
        {
          label: 'string';
          variant: 'string';
          url: 'string';
        },
        {
          label: 'string';
          variant: 'string';
          url: 'string';
        }
      ];
    };
  };
}

export interface EditorJsonContent {
  __type?: 'editor.json';
  partnerID: string;
  showVariant: string;
  variantConfig: {
    displayOpeningHours: boolean;
    displayCtaBar: boolean;
    displayOfficialName: boolean;
    displayPhone: boolean;
    displayEmail: boolean;
    isLinkedToPWS: boolean;
    showMoreInfoLink: boolean;
    hasOuterSpacing: boolean;
    isGoogleMapLink: boolean;
    showImage: boolean;
    showServiceList: boolean;
    showJsonLd: boolean;
    ctaBarButtonConfig: [
      {
        label: 'string';
        variant: 'string';
        url: 'string';
      },
      {
        label: 'string';
        variant: 'string';
        url: 'string';
      }
    ];
  };
}
export function mapContent(content: EditorJsonContent | FalconContent): EditorJsonContent {
  if (content.__type !== 'aem-headless') {
    return content as EditorJsonContent;
  }
  const normalizedContent: EditorJsonContent = {
    ...content.elements,
  };

  return normalizedContent;
}
