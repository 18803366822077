/**
 * This is the starting point of your application.
 * oneAudi OS and Feature Hub Apps will use this file to bootstrap the app.
 */
import React from 'react';
import { App, ProviderProps as AppProps } from '@volkswagen-onehub/oneaudi-os-react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Loader, Text } from '@audi/audi-ui-react';
import { useQuery } from '@apollo/client';
import { useI18n } from '@volkswagen-onehub/audi-i18n-context';
import { queryString } from '../queryHook';
import { ConfigProps, PartnerBusinessCard } from './components/PartnerBusinessCard';
import { mapContent } from './components/ContentInterfaces';
import FeatureServiceContext from './FeatureServiceContext';

import { Container } from './style';
import { pruneOpeningHours } from '../lib/use-partner-data';
import { messages } from '../i18n/messages';

interface FeatureAppProps extends Omit<AppProps, 'config'> {
  config?: Config;
}

interface Config extends Omit<ConfigProps, 'partnerID'> {
  partnerID?: string;
}

interface PBCProps {
  config?: Config;
}

const PBCWrapper: React.FC<PBCProps> = ({ config }: PBCProps) => {
  const [pbcConfig, setPbcConfig] = React.useState<ConfigProps>();

  React.useEffect(() => {
    if (config?.partnerID) {
      setPbcConfig({
        ...config,
        partnerID: config?.partnerID,
      });
    } else {
      setPbcConfig({
        ...config,
        partnerID: 'DEUA26603',
      });
      // eslint-disable-next-line
      console.error(
        'Neither a content id to fetch partnerID from content or a config with partnerID is specified! Component will not render!'
      );
    }
  }, [config]);

  React.useEffect(() => {
    if (config?.partnerID) {
      setPbcConfig({
        ...config,
        variantConfig: {
          ...config?.variantConfig,
        },
        partnerID: config?.partnerID as string,
      });
    } else {
      // eslint-disable-next-line
      console.error('"partnerID" is missing! Component will not render!');
    }
  }, [config]);

  const partnerId = pbcConfig?.partnerID;

  // eslint-disable-next-line

  const { data, loading } = useQuery(queryString, {
    variables: { partnerId },

    fetchPolicy: 'no-cache',
  });

  let dealerData: any;

  if (loading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Loader />
      </div>
    );
  }

  if (data) {
    const { dealerById } = data;
    if (dealerById?.openingHours) {
      dealerById.openingHours = pruneOpeningHours(dealerById.openingHours);
      dealerData = dealerById;
    }
    dealerData = dealerById;

    return <PartnerBusinessCard config={pbcConfig} data={dealerData} />;
  }

  console.error('Partner data does not exist.');
  return (
    <div style={{ textAlign: 'left' }}>
      <Text variant="order3" spaceStackEnd="xxs">
        {useI18n(messages.missingPartnerDataErrorHeader)}
      </Text>
      <Text variant="copy1">{useI18n(messages.missingPartnerDataErrorMessage)}</Text>
    </div>
  );
};

PBCWrapper.defaultProps = {
  config: undefined,
};

const FeatureApp: React.FC<FeatureAppProps> = ({
  id = 'oneaudi-feature-app-pbc',
  config,
  ...inherited
}: FeatureAppProps) => {
  const context = React.useContext(FeatureServiceContext);
  const [rawContent, setRawContent] = React.useState<any>(context.contentService?.getContent());

  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      document.addEventListener(
        'pbcCtaClicked',
        (e: any) => {
          console.log(e?.detail);
        },
        false
      );
    }
    return () => {
      if (typeof document !== 'undefined') {
        document.removeEventListener(
          'pbcCtaClicked',
          (e) => {
            console.log(e);
          },
          false
        );
      }
    };
  });

  React.useEffect(() => {
    const listener = () => {
      setRawContent(context.contentService?.getContent());
    };
    context.contentService?.onContentChange(listener);

    return () => {
      context.contentService?.removeOnContentChange(listener);
    };
  }, [context.contentService]);
  // eslint-disable-next-line
  let content: any = mapContent(rawContent);

  // eslint-disable-next-line
  let ctaBarButtonConfigArray = [
    {
      label: content?.variantConfig_ctaBarButtonConfig_0_label,
      url: content?.variantConfig_ctaBarButtonConfig_0_url,
      variant: content?.variantConfig_ctaBarButtonConfig_0_variant,
    },
    {
      label: content?.variantConfig_ctaBarButtonConfig_1_label,
      url: content?.variantConfig_ctaBarButtonConfig_1_url,
      variant: content?.variantConfig_ctaBarButtonConfig_1_variant,
    },
  ];
  // eslint-disable-next-line
  const variantConfigHeadless: any = {
    ctaBarButtonConfig: ctaBarButtonConfigArray,
    displayCtaBar: content?.variantConfig_displayCtaBar,
    displayEmail: content?.variantConfig_displayEmail,
    displayOfficialName: content?.variantConfig_displayOfficialName,
    displayOpeningHours: content?.variantConfig_displayOpeningHours,
    displayPhone: content?.variantConfig_displayPhone,
    hasOuterSpacing: content?.variantConfig_hasOuterSpacing,
    isGoogleMapLink: content?.variantConfig_isGoogleMapLink,
    isLinkedToPWS: content?.variantConfig_isLinkedToPWS,
    showImage: content?.variantConfig_showImage,
    showJsonLd: content.variantConfig_showJsonLd,
    showMoreInfoLink: content?.variantConfig_showMoreInfoLink,
    showServiceList: content?.variantConfig_showServiceList,
  };
  const pbcConfig = {
    partnerID: config?.partnerID ? config?.partnerID : content?.partnerID,
    variantConfig: config?.variantConfig
      ? config?.variantConfig
      : content?.variantConfig || variantConfigHeadless,
    showVariant: config?.showVariant ? config?.showVariant : content?.showVariant,
  };

  const appConfig: { [k: string]: string } = { appName: __FEATURE_APP_NAME__ };

  const standAlonePBC = !config?.partnerID;

  return (
    <App config={appConfig} id={id} {...inherited}>
      <div data-test-id="PBC">
        <Container variant={content?.showVariant} standAlonePBC={standAlonePBC}>
          <PBCWrapper config={pbcConfig} />
        </Container>
      </div>
    </App>
  );
};

export default FeatureApp;
