import * as React from 'react';
import { ThemeProvider, audiLightTheme } from '@audi/audi-ui-react';
import { Partner } from '@volkswagen-onehub/audi-partner-utils';
import { rehydrateValue, storeForHydration } from '@volkswagen-onehub/oneaudi-os-utils';
import { useInViewEffect } from '@volkswagen-onehub/audi-etron-gt-utils-feature-app';
import FeatureServiceContext from '../FeatureServiceContext';
import { useTrackingManager } from '../useTrackingManager';
import { PartnerBusinessCardFullLong } from './PartnerBusinessCardFullLong';
import { PartnerBusinessCardAddress } from './PartnerBusinessCardAddress';
import { PartnerBusinessCardTag } from './PartnerBusinessCardTag';
import { PartnerBusinessCardFixedNarrow } from './PartnerBusinessCardFixedNarrow';

import { createPartner, DepartmentOpeningHours } from './PartnerData';
import { CtaBarButtonConfig } from './CtaBar';

import { PartnerProvider } from '../PartnerProvider';

type PartnerBusinessCardState = {
  content?: ConfigProps;
};

export type VariantConfig = {
  displayOpeningHours?: boolean;
  displayCtaBar?: boolean;
  displayOfficialName?: boolean;
  displayEmail?: boolean;
  displayPhone?: boolean;
  isLinkedToPWS?: boolean;
  showMoreInfoLink?: boolean;
  hasOuterSpacing?: boolean;
  isGoogleMapLink?: boolean;
  showImage?: boolean;
  showServiceList?: boolean;
  showJsonLd?: boolean;
  ctaBarButtonConfig?: CtaBarButtonConfig;
};

export interface ConfigProps {
  partnerID: string;
  showVariant?:
    | 'Show all dealer info (long layout)'
    | 'Show dealer name, address, and contact information only'
    | 'Show dealer name and address only'
    | 'Show all dealer info (narrow layout)';
  variantConfig?: VariantConfig;
  locale?: string;
}

const hydrationStorePartnerData = `${__FEATURE_APP_NAME__}-partner-data`;
const rehydratedPartnerData = rehydrateValue<Partner | undefined>(hydrationStorePartnerData);

export interface PartnerBusinessCardProps {
  config?: ConfigProps;
  data?: any;
}

export const PartnerBusinessCard = (props: PartnerBusinessCardProps): React.ReactElement => {
  const context = React.useContext(FeatureServiceContext);
  const [state, setState] = React.useState<PartnerBusinessCardState>({
    content: context.contentService?.getContent(),
  });
  const trackingManager = useTrackingManager();
  const { config, data } = props;

  context.contentService?.onContentChange(async () => {
    setState({ content: context.contentService?.getContent() });
  });

  const pbcvariant: string =
    state.content?.showVariant || config?.showVariant || 'Show all dealer info (long layout)';

  let partnerID: string;
  if (state.content?.partnerID) {
    partnerID = state.content?.partnerID;
  } else if (config?.partnerID) {
    partnerID = config?.partnerID;
  } else {
    // eslint-disable-next-line no-console
    console.debug('"partnerID" is missing! Partner data will not be fetched');
    // TODO Refactor component to render variants depending on partnerID is defined or not
    partnerID = '';
  }
  const [fetchedPartnerData, setFetchedPartnerData] = React.useState<Partner | undefined>(
    rehydratedPartnerData
  );

  const partner = createPartner(fetchedPartnerData);

  const variantConfig: VariantConfig | undefined = config?.variantConfig
    ? config.variantConfig
    : state.content?.variantConfig;

  React.useEffect(() => {
    if (data) {
      setFetchedPartnerData(data);
      storeForHydration(hydrationStorePartnerData, data);
    }
  }, [data]);

  React.useEffect(() => {
    if (typeof window !== 'undefined') trackingManager.pageLoad(pbcvariant, partnerID);
  }, [pbcvariant, partnerID, trackingManager]);

  // TODO refactor impression tracking only should happen when partner data are loaded!
  useInViewEffect(() => {
    trackingManager.impression(
      pbcvariant,
      partner?.nameEnhanced || '',
      partner?.openingHours?.departments as DepartmentOpeningHours[],
      variantConfig?.ctaBarButtonConfig
    );
  });

  return (
    <>
      {partner && (
        <ThemeProvider theme={audiLightTheme}>
          <PartnerProvider partner={partner}>
            {pbcvariant === 'Show all dealer info (long layout)' && (
              <PartnerBusinessCardFullLong {...variantConfig} />
            )}
            {pbcvariant === 'Show dealer name, address, and contact information only' && (
              <PartnerBusinessCardAddress {...variantConfig} />
            )}
            {pbcvariant === 'Show dealer name and address only' && (
              <PartnerBusinessCardTag {...variantConfig} />
            )}
            {(pbcvariant === 'Show all dealer info (narrow layout)' ||
              pbcvariant === 'fixed-narrow') && (
              <PartnerBusinessCardFixedNarrow {...variantConfig} />
            )}
          </PartnerProvider>
        </ThemeProvider>
      )}
    </>
  );
};
