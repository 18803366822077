import * as React from 'react';
import { Fragment } from 'react';
import styled from 'styled-components';
import { LayoutItem, Text } from '@audi/audi-ui-react';
import { VariantConfig } from './PartnerBusinessCard';
import { JsonLd } from './Json-ld';
import { PartnerContext } from '../PartnerProvider';

const StyledHeadlineAsLink = styled.div`
  > a {
    border-bottom: 0;
  }
`;

const AddressContainer = styled(LayoutItem)`
  max-width: 375px;
`;

export const PartnerBusinessCardAddress = (variantConfig: VariantConfig): React.ReactElement => {
  const { partner } = React.useContext(PartnerContext);

  const { isLinkedToPWS, hasOuterSpacing, isGoogleMapLink, showJsonLd } = variantConfig;
  let { displayOfficialName, displayEmail, displayPhone } = variantConfig;

  displayOfficialName = (displayOfficialName && partner.officialName) as boolean;
  displayEmail = (displayEmail && partner.email) as boolean;
  displayPhone = (displayPhone && partner.phone) as boolean;

  const partnerName = displayOfficialName ? partner.officialName : partner.nameEnhanced;
  return (
    <AddressContainer spacing={hasOuterSpacing ? ['m'] : undefined}>
      {partner && (
        <>
          {showJsonLd && <JsonLd data={partner} />}
          <Text as="h2" variant="order3" weight="bold" data-testid="name">
            {isLinkedToPWS && partner.url ? (
              <StyledHeadlineAsLink>
                <a href={partner.url}>{partnerName}</a>
              </StyledHeadlineAsLink>
            ) : (
              partnerName
            )}
          </Text>
          {isGoogleMapLink && (
            <Text spaceStackStart="m">
              {partner.address.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={index}>
                  {item}
                  <br />
                </Fragment>
              ))}
            </Text>
          )}
          {(displayPhone || displayEmail) && (
            <Text spaceStackStart="m">
              {displayPhone && partner.phone}
              <br />
              {displayEmail && partner.email}
            </Text>
          )}
        </>
      )}
    </AddressContainer>
  );
};
