import React from 'react';
import { Button } from '@audi/audi-ui-react';
// import { PartnerContext } from '../PartnerProvider';
import { useTrackingManager } from '../useTrackingManager';
import { CtaButtonProps } from './CtaBar';
import { getCtaLabel } from '../../lib/ctaLabel';

export type ClickHandler = (
  event: React.MouseEvent<HTMLElement>,
  cta: string,
  label: string
) => unknown;

export type CtaCallbackButtonProps = CtaButtonProps & {
  clickHandler: ClickHandler;
};

export const CtaCallbackButton = ({
  variant,
  clickHandler,
  label,
  displayname,
  position,
}: CtaCallbackButtonProps): React.ReactElement => {
  // const { partner } = React.useContext(PartnerContext);
  const trackingManager = useTrackingManager();
  return (
    <Button
      variant={variant}
      onClick={(event) => {
        clickHandler(event, `${variant}-cta${position}`, label);
        trackingManager.click('internal_link', '', 'cta', label, displayname, position, label);
      }}
    >
      {getCtaLabel(label)}
    </Button>
  );
};
