import React from 'react';
import { PbcPartner } from './components/PartnerData';

export interface PartnerProviderProps {
  partner: PbcPartner;
}

export interface IPartnerContext {
  partner: PbcPartner;
  hasOpeningHours: boolean;
}

export const PartnerContext = React.createContext<IPartnerContext>({
  partner: {
    partnerId: '',
    officialName: '',
    address: [''],
    locationImageUrl: '',
    vCardUrl: '',
    myAudiUrl: '',
    directionsUrl: '',
    services: [''],
    nameEnhanced: '',
  },
  hasOpeningHours: false,
});

export const PartnerProvider: React.FC<PartnerProviderProps> = ({ partner, children }) => {
  const hasOpeningHours = !!(
    partner.openingHours?.departments && partner.openingHours?.departments?.length > 0
  );

  return (
    <PartnerContext.Provider value={{ partner, hasOpeningHours }}>
      {children}
    </PartnerContext.Provider>
  );
};
