import * as React from 'react';
import { LayoutProps, ButtonGroup, ButtonProps } from '@audi/audi-ui-react';
import { ClickHandler, CtaCallbackButton, CtaCallbackButtonProps } from './CtaCallbackButton';
import { CtaUrlButton, CtaUrlButtonProps } from './CtaUrlButton';

export type CtaBarButtonConfig = {
  label: string;
  variant: ButtonProps['variant'];
  url?: string;
  clickHandler?: ClickHandler;
}[];

export type CtaBarProps = LayoutProps & {
  ctaBarButtonConfig: CtaBarButtonConfig;
  displayname: string;
};

export type CtaButtonProps = {
  displayname: string;
  label: string;
  position: string;
  variant: ButtonProps['variant'];
};

export const CtaBar = ({ ctaBarButtonConfig, displayname }: CtaBarProps): React.ReactElement => {
  const [ctaButtons, setCtaButtons] = React.useState<(JSX.Element | undefined)[]>();

  React.useEffect(() => {
    const ctaBtns = ctaBarButtonConfig.map((buttonConfig, index) => {
      const { label, variant, url, clickHandler } = buttonConfig;
      console.log('🚀 ~ file: CtaBar.tsx:31 ~ ctaBtns ~ clickHandler:', clickHandler);
      const position = (index + 1).toString();

      if (url) {
        if (clickHandler && url) {
          // eslint-disable-next-line no-console
          console.debug(
            'CTA has both clickHandler and url configured. Url is prefered and will be used!',
            buttonConfig
          );
        }

        const ctaUrlButtonProps: CtaUrlButtonProps = {
          displayname,
          label,
          variant,
          url,
          position,
        };

        return <CtaUrlButton key={position} {...ctaUrlButtonProps} />;
      }

      if (clickHandler) {
        const ctaCallbackButtonProps: CtaCallbackButtonProps = {
          displayname,
          label,
          variant,
          clickHandler,
          position,
        };

        return <CtaCallbackButton key={position} {...ctaCallbackButtonProps} />;
      }

      // eslint-disable-next-line no-console
      console.debug('CTA config is missing url or clickHandler!', buttonConfig);
      return undefined;
    });

    setCtaButtons(ctaBtns);
  }, [ctaBarButtonConfig]);

  return (
    <>
      {ctaBarButtonConfig.length > 0 && (
        <ButtonGroup variant="block-buttons" spaceStackStart="l" spaceStackEnd="l">
          {ctaButtons}
        </ButtonGroup>
      )}
    </>
  );
};
