/* eslint-disable react/prop-types */
import React from 'react';

interface JsonLdProps {
  // eslint-disable-next-line
  readonly data: any;
}
// eslint-disable-next-line
export const JsonLd: React.FunctionComponent<JsonLdProps> = ({ data }) => {
  const addressParts = data.address[1].split(' ');
  // eslint-disable-next-line
  const openingHoursDepartments = data.openingHours.departments.map((department: any) => ({
    name: `${department.departmentName}`,
    image: `${data.locationImageUrl}`,
    // eslint-disable-next-line
    openingHoursSpecification: department.openingHours.map((item: any) => ({
      '@type': 'OpeningHoursSpecification',
      dayOfWeek: [`http://schema.org/${item.id.charAt(0).toUpperCase() + item.id.slice(1)}`],
      opens: `${item.timeRanges.length > 0 && item.timeRanges[0].openTime}`,
      closes: `${item.timeRanges.length > 0 && item.timeRanges[0].closeTime}`,
    })),
    '@type': 'LocalBusiness',
  }));

  const jsonlddata = {
    '@type': 'LocalBusiness',
    url: `${data.url}`,
    name: `${data.nameEnhanced}`,
    telephone: `${data.phone}`,
    image: `${data.locationImageUrl}`,
    geo: {
      '@type': 'GeoCoordinates',
      latitude: data.lat,
      longitude: data.long,
    },
    address: {
      '@type': 'PostalAddress',
      addressCountry: 'DE',
      addressLocality: `${addressParts[1]}`,
      postalCode: `${addressParts[0]}`,
      streetAddress: `${data.address[0]}`,
    },
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: `${data.phone}`,
      email: `${data.email}`,
      contactType: 'Customer Service',
    },
    // eslint-disable-next-line
    openingHoursSpecification: data.openingHours.departments[0].openingHours.map((item: any) => ({
      '@type': 'OpeningHoursSpecification',
      dayOfWeek: [`http://schema.org/${item.id.charAt(0).toUpperCase() + item.id.slice(1)}`],
      opens: `${item.timeRanges.length > 0 && item.timeRanges[0].openTime}`,
      closes: `${item.timeRanges.length > 0 && item.timeRanges[0].closeTime}`,
    })),
    department: openingHoursDepartments,
    '@context': 'https://schema.org',
  };

  return (
    <script
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        // eslint-disable-next-line @typescript-eslint/naming-convention
        __html: JSON.stringify(jsonlddata),
      }}
    />
  );
};
